import update from "immutability-helper";

class damageCalculatorLib {

    static determineIfAluminumFromCalculateRequest = (data) => {
        if (!Array.isArray(data)) {
            return false;
        }
        return data.some((lineItem) => {
            return lineItem.repairDetails.some((details) => {
                details.question.toLowerCase().includes('aluminum') && ['yes', 'true'].includes(details.question.toLowerCase())

                return (details.nestedQuestions || []).some((nestedQuestion) => (
                    nestedQuestion.question.toLowerCase().includes('aluminum') && ['yes', 'true'].includes(nestedQuestion.answer.toLowerCase())
                ))
            })
        });
    };

    static getRepairDetails = (questions, type) => {
        let repairDetails = [];
        let measurements = {};

        questions.forEach(question => {
            // TODO Refactor this code to remove duplicate code
            let answer = {
                id: question.id,
                estimatorType: type,
                question: question.question,
                answer: question.answer
            };

            if (['Range', 'Select'].includes(question.uiElementType)) {
                measurements = {...measurements, ...this.getMeasurements(answer)};
            }

            let nestedQuestions = null;
            if (question.nestedQuestions) {
                nestedQuestions = [];
                question.nestedQuestions.forEach(nestedQuestion => {
                    if (nestedQuestion.answer) {
                        nestedQuestions.push({
                            id: nestedQuestion.id,
                            estimatorType: type,
                            question: nestedQuestion.question,
                            answer: nestedQuestion.answer
                        })
                    }
                    if (['Range', 'Select'].includes(nestedQuestion.uiElementType)) {
                        measurements = {...measurements, ...this.getMeasurements(nestedQuestion)};
                    }
                });
                nestedQuestions = nestedQuestions.length > 0 ? {nestedQuestions: nestedQuestions} : null;
            }
            if (answer.answer || nestedQuestions) {
                repairDetails.push({...answer, ...nestedQuestions});
            }
        });

        console.log("repair details", repairDetails);
        return repairDetails;
    };

    static getAllMeasurements = (questions, type) => {
        let measurements = {};

        questions.forEach(question => {
            let answer = {
                id: question.id,
                estimatorType: type,
                question: question.question,
                answer: question.answer
            };

            if (['Range', 'Select'].includes(question.uiElementType)) {
                measurements = {...measurements, ...this.getMeasurements(answer)};
            }

            let nestedQuestions = null;
            if (question.nestedQuestions) {
                nestedQuestions = [];
                question.nestedQuestions.forEach(nestedQuestion => {
                    if (nestedQuestion.answer) {
                        nestedQuestions.push({
                            id: nestedQuestion.id,
                            estimatorType: type,
                            question: nestedQuestion.question,
                            answer: nestedQuestion.answer
                        })
                    }
                    if (['Range', 'Select'].includes(nestedQuestion.uiElementType)) {
                        measurements = {...measurements, ...this.getMeasurements(nestedQuestion)};
                    }
                });
                nestedQuestions = nestedQuestions.length > 0 ? {nestedQuestions: nestedQuestions} : null;
            }
        });

        return measurements;
    };

    static getMeasurements = (answer) => {
        if (answer.question.startsWith('Length of Damage')) {
            return ({length: answer.answer});
        } else if (answer.question.startsWith('Width of Damage')) {
            return ({width: answer.answer});
        } else if (answer.question.startsWith('Prominent Dent')) {
            return ({size: answer.answer})
        } else if (answer.question.startsWith('Total Number')) {
            return ({dents: answer.answer})
        }
    };

    static validateForm = (questions) => {
        let isValid = true;
        let newQuestions = [];

        questions.forEach(question => {
            let questionIsValid = this.validateQuestion(question);
            if (question.invalid !== !questionIsValid) {
                let newQuestion = {...question, invalid: !questionIsValid};
                newQuestions.push(newQuestion);
            } else {
                newQuestions.push(question);
            }

            if (!questionIsValid) {
                isValid = false
            }

            if (question.nestedQuestions) {
                let newNestedQuestions = [];
                question.nestedQuestions.forEach(nestedQuestion => {
                    let nestedQuestionIsValid = this.validateQuestion(nestedQuestion);
                    if (nestedQuestion.invalid !== !nestedQuestionIsValid) {
                        let newNestedQuestion = {...nestedQuestion, invalid: !nestedQuestionIsValid};
                        newNestedQuestions.push(newNestedQuestion);
                    } else {
                        newNestedQuestions.push(nestedQuestion);
                    }
                    if (!nestedQuestionIsValid) {
                        isValid = false
                    }
                });
                newQuestions[newQuestions.length - 1].nestedQuestions = newNestedQuestions;
            }
        });

        return {isValid, questions: newQuestions};
    };

    static resetFormQuestions = (questions) => {
        let newQuestions = update(questions, {});

        newQuestions.forEach(question => {
            question.invalid = false;
            question.optional = null;
            delete question.answer;

            if (question.nestedQuestions) {
                question.nestedQuestions.forEach(nestedQuestion => {
                    nestedQuestion.invalid = false;
                    nestedQuestion.optional = null;
                    delete nestedQuestion.answer;
                })
            }
        });
        let newState = {};
        newState.questions = newQuestions;
        newState.isValid = true;
        newState.length = 0;
        newState.width = 0;
        newState.dents = 0;
        newState.size = '';
        newState.statusMessage = {text: '', color: ''};
        newState.lastCalculationCallFailed = false;
        newState.isRepairCostEffective = true;
        return newState;
    };

    static setAnswer = (state, id, answer) => {
        let indexes = this.getIndexByQuestionId(state.questions, id);
        let newState = {};
        if (indexes.length > 1) {
            newState = update(state, {
                questions: {
                    [indexes[0]]: {
                        nestedQuestions: {
                            [indexes[1]]: {$merge: {answer: answer}}
                        }
                    }
                }
            });
        } else {
            newState = update(state, {
                questions: {
                    [indexes[0]]: {$merge: {answer: answer}}
                }
            });
        }
        return {newState};
    };

    static getIndexByQuestionId = (questions, id) => {
        let splitId = id.split('_');
        let index = [];
        index.push(questions.findIndex(question => {
            return question.id === splitId[0]
        }));
        if (splitId.length > 1) {
            index.push(questions[index[0]].nestedQuestions.findIndex(function (nestedQuestion) {
                return nestedQuestion.id === id;
            }));
        }
        return index;
    };

    static markQuestionsOptional = (questions) => {
        let updatedQuestions = update(questions, {});

        //reset optional flag
        updatedQuestions.forEach(resetQuestion => {
            resetQuestion.optional = false;
            if (resetQuestion.nestedQuestions) {
                resetQuestion.nestedQuestions.forEach(resetNestedQuestion => {
                    resetNestedQuestion.optional = false;
                })
            }
        });

        //set as optional
        updatedQuestions.forEach(question => {
            if (question.questionIdsOptionalForAnswers) {
                if (question.questionIdsOptionalForAnswers.answers.includes(question.answer)) {
                    question.questionIdsOptionalForAnswers.questionsIds.forEach(id => {
                        let indexes = this.getIndexByQuestionId(questions, id);
                        if (indexes.length > 1) {
                            updatedQuestions[indexes[0]].nestedQuestions[indexes[1]].optional = true;
                        } else {
                            updatedQuestions[indexes[0]].optional = true;
                        }
                    })
                }
            }
        });

        return {newState: updatedQuestions};
    };

    static validateQuestion = (validationQuestion) => {
        let isValid = false;
        if (validationQuestion.mandatory === 'N' || validationQuestion.optional) {
            isValid = true;
        } else if (validationQuestion.mandatory === 'Y' && validationQuestion.answer) {
            isValid = true
        }

        return isValid;
    };

    static totalDamageHours = (damages) => {
        if (damages.length === 0) {
            return 0.0
        }

        return damages.reduce(function (previous, damage) {
            return previous + (damage.hours || 0);
        }, 0)
    };

    static totalDamageCost = (damages) => {
        if (damages.length === 0) {
            return 0.0
        }

        return damages.reduce(function (previous, damage) {
            return previous + (damage.cost || 0);
        }, 0)
    };

    static isRepairCostEffective = (response) => {
        if (response[0].totalEstimatedRepairCost >= 100000) {
            console.log("isRepairCostEffective (above 100k):", false);
            return false;
        }
        if (response[0].errorMessage === "Dent Repair is not Cost Effective. Please select Replace as action") {
            console.log("isRepairCostEffective (err msg):", false);
            return false;
        }
        console.log("isRepairCostEffective:", true);
        return true;
    }
}

export default damageCalculatorLib;
