import React from 'react';
import {connect} from "react-redux";

import Col from '@prism/col'
import Row from '@prism/row';
import Form from '@prism/form';
import Button from '@prism/button'
import WorkList from "./workList/WorkList";
import ErrorBoundary from "./common/ErrorBoundary";

import MessageCenter from "./MessageCenter/MessageCenter";
import {togglePushPanel} from "../actions/pushPanelActions";
import {getAuctionLocations} from "../actions/locationActions";

import {Typeahead} from "react-bootstrap-typeahead";
import {setAuctionCode, setScanAuctionCode, setWorkOrderInfo} from "../actions/scanActions";
import {isNativeApp} from "../utils/utils";
import {resetSubmitStatus} from "../actions/naviationActions";
import {REDUX_STATE_VERSION} from "../config/env";
import ContinueIncompleteWorkOrderModal from "./ContinueIncompleteWorkOrderModal";
import {
    hideContinueIncompleteWorkOrderModal,
    hideLockedRecordModal,
    showContinueIncompleteWorkOrderModal
} from "../actions/globalDisplayActions";
import DeleteIncompleteInspectionModal from "./DeleteIncompleteInspectionModal";
import {evaluateDeviceStorageFlag, getAppVersion} from "../actions/appFlagsActions";
import {LOCKED_RECORD_BODY, LOCKED_RECORD_HEADER, OK} from "../utils/constants";
import ConfirmationOrDecisionModal from "./ConfirmationOrDecisionModal";

class ScannerInput extends React.Component {

    workOrderRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            auctionCode: props.auctionCode,
            scanAuctionCode: props.scanAuctionCode,
            incompleteWorkList: null,
            shouldRestartWorkOrderResolve: null,
        };
    }

    handleLocationChange = async (e) => {
        const auctionCode = e.length ? e[0].locationCode.toUpperCase() : '';
        await this.props.setScanAuctionCode(auctionCode);
        if (isNativeApp()) {
            window.NativeApp.setLocationCode(auctionCode);
        }
    };

    handleWorkOrderChange = async (e) => {
        const workOrderNumber = [...e.target.value.matchAll(/\d+/g)].join()
        await this.props.updateWorkOrderNumber(workOrderNumber)
    };

    componentDidMount() {
        this.props.getAuctionLocations();
        this.getVehiclesWithState();
        this.props.setChildFunction(this.checkAndHandleExistingWorkOrder);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.navigation.submitSuccessful) {
            this.props.updateWorkOrderNumber('');
            this.props.resetSubmitStatus();
        }
        this.enableOrDisableStartButton(prevProps);
    }

    scanBarOrQRCode = async () => {
        window.NativeApp.scanBarCode();
    };

    hasWorkOrderBeenStarted = (workOrderNumber) => {
        return this.state.incompleteWorkList
            ? this.state.incompleteWorkList.some(wo => parseInt(wo.workOrder) === parseInt(workOrderNumber))
            : false;
    };

    showContinueIncompleteWorkOrderModalAction = () => {
        this.props.showContinueIncompleteWorkOrderModal();
        return new Promise((resolve) => {
            this.setState({shouldRestartWorkOrderResolve: resolve});
        });
    };

    checkAndHandleExistingWorkOrder = async (workOrderNumber) => {
        if (this.hasWorkOrderBeenStarted(workOrderNumber)) {
            // Returns true if user selects Start Over for work order, returns false if Continue selected
            const shouldRestartWorkOrder = await this.showContinueIncompleteWorkOrderModalAction();
            if (shouldRestartWorkOrder) {
                window.NativeApp.removeVehicleState(workOrderNumber, REDUX_STATE_VERSION)
            }
            this.props.hideContinueIncompleteWorkOrderModal();
        }
    };

    handleClick = async () => {
        const auctionCode = this.props.scanAuctionCode;
        const workOrderNumber = this.props.workOrderNumber;
        await this.props.setWorkOrderInfo({auctionCode: auctionCode, workOrderNumber: workOrderNumber});
        await this.checkAndHandleExistingWorkOrder(workOrderNumber);
        await this.props.submitScan(auctionCode, workOrderNumber);
    };

    handleOnKeyDown = async (event) => {
        if (event.key === 'Enter' && !this.state.disabled) {
            await this.handleClick();
        }
    };

    enableOrDisableStartButton = (prevProps) => {
        const hasAuctionCode = !!this.props.scanAuctionCode;
        const hasWorkOrder = !!this.props.workOrderNumber;
        const scanSubmitted = !this.props.workOrderNumber && prevProps.workOrderNumber;
        const validWorkOrder = hasWorkOrder && (this.props.workOrderNumber.length === 7);
        const startButtonEnabled = !this.state.disabled;
        const startButtonDisabled = this.state.disabled;

        if (!hasAuctionCode && startButtonEnabled) {
            this.disableStartButton();
        } else if (hasAuctionCode && !validWorkOrder && startButtonEnabled) {
            this.disableStartButton();
        } else if (hasAuctionCode && validWorkOrder && startButtonDisabled) {
            this.enableStartButton();
        } else if (scanSubmitted) {
            this.disableStartButton()
        }
    };

    shouldScannerBeEnabled = () => {
        return isNativeApp()
            && (this.props.scanAuctionCode.trim() !== undefined && this.auctionInList(this.props.scanAuctionCode.trim()))
    };

    auctionInList = (text) => {
        return this.props.locations.find(item => item.locationCode === text);
    };

    enableStartButton = () => {
        this.setState({disabled: false});
    };

    disableStartButton = () => {
        this.setState({disabled: true});
    };

    getVehiclesWithState = () => {
        this.setState({incompleteWorkList: isNativeApp() && evaluateDeviceStorageFlag(getAppVersion()) ? JSON.parse(window.NativeApp.getVehiclesWithState(REDUX_STATE_VERSION)) : null})
    }

    render() {
        return (
            <div>

                <Row>
                    <Col className="col-10 m-auto">
                        <MessageCenter/>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-10 m-auto">
                        <Form.Group row id='scanner-panel'>
                            <ContinueIncompleteWorkOrderModal resolve={this.state.shouldRestartWorkOrderResolve}/>
                            <DeleteIncompleteInspectionModal getVehiclesWithState={this.getVehiclesWithState}/>
                            <h1 style={{color: '#FFF'}}>SCAN</h1>

                            <Col xs={12} id="auction-list" className="input-group mb-3 bg-white">
                                <i className="icon text-input__icon-left prism-icon-location-area "/>
                                <Typeahead
                                    id="auction-selection-list"
                                    placeholder="Select Auction"
                                    labelKey={option => `${option.locationName} - ${option.locationCode}`}
                                    options={this.props.locations}
                                    onChange={this.handleLocationChange}
                                    className="text-input__has-icon-left flex-grow-1"
                                    selected={this.props.locations.filter(item => item.locationCode === this.props.scanAuctionCode)}
                                    key={this.props.scanAuctionCode}
                                />
                            </Col>
                            <Col xs={12} className="input-group mb-3 px-0">
                                <Button color="primary"
                                        size="m"
                                        className="btn-icon-only mr-2"
                                        style={{width: '20%'}}
                                        disabled={!this.shouldScannerBeEnabled()}
                                        onClick={this.scanBarOrQRCode}>
                                    <i className='icon prism-icon-barcode' style={{fontSize: "4rem"}}/>
                                </Button>
                                <input ref={this.workOrderRef}
                                       name="workOrderNumber"
                                       type="number"
                                       placeholder="Work Order Number"
                                       value={this.props.workOrderNumber}
                                       id="scanner_work_order_number"
                                       className="form-control"
                                       onKeyDown={this.handleOnKeyDown}
                                       onChange={this.handleWorkOrderChange}
                                />
                            </Col>
                            <Col xs={12} className="mb-2 px-0">
                                <Button color="primary"
                                        className="btn-full-width"
                                        id="scanner_submit"
                                        disabled={this.state.disabled}
                                        onClick={this.handleClick}>Start Inspection
                                </Button>
                            </Col>
                        </Form.Group>
                        <ErrorBoundary>
                            <WorkList
                                submitScan={this.props.submitScan}
                                incompleteWorkList={this.state.incompleteWorkList}
                                checkAndHandleExistingWorkOrder={this.checkAndHandleExistingWorkOrder}
                            />
                        </ErrorBoundary>
                    </Col>
                    <ConfirmationOrDecisionModal
                        shouldShowModal={this.props.showLockedRecordModal}
                        modalHeader={LOCKED_RECORD_HEADER}
                        modalBody={LOCKED_RECORD_BODY}
                        modalType="locked-record"
                        confirmationText={OK}
                        handleClose={this.props.hideLockedRecordModal}
                    />
                </Row>
                <Row className="version-footer text-white container">
                    <Col sm={8} className="m-auto text-center">
                        App: {this.props.appVersion} :: View: {this.props.webVersion}
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapDispatchToProps = {
    togglePushPanel,
    getAuctionLocations,
    setAuctionCode,
    setScanAuctionCode,
    resetSubmitStatus,
    setWorkOrderInfo,
    showContinueIncompleteWorkOrderModal,
    hideContinueIncompleteWorkOrderModal,
    hideLockedRecordModal
};

function mapStateToProps({locations, workOrderInfo, appFlags, navigation, globalDisplay}) {
    const {auctionCode, scanAuctionCode} = workOrderInfo;
    const {webVersion, appVersion} = appFlags;
    const {showLockedRecordModal} = globalDisplay;
    return {locations, auctionCode, scanAuctionCode, webVersion, appVersion, navigation, showLockedRecordModal};
}

export default connect(mapStateToProps, mapDispatchToProps)(ScannerInput);