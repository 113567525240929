import React, {useState} from 'react';
import Api from '../../../Api';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import Form from "@prism/form";
import Input from "@prism/input";
import Row from "@prism/row";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {APPROVALS_API_URL} from "../../../../config/env";

function ItemDropdown(props) {

    const [options, setOptions] = useState([]);
    const [itemSearchResults, setItemSearchResults] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState("");
    const [itemValidationMessage, setItemValidationMessage] = useState(null);
    const [isItemValid, setIsItemValid] = useState(true);
    const [item, setItem] = useState(null);

    const itemsSearchApi = (text) =>
        Api._fetch(
            `${APPROVALS_API_URL}/smartInspect/art/items/search?description=` +
            encodeURIComponent(text)
        );
    const itemsSearchAPIDebounced = AwesomeDebouncePromise(itemsSearchApi, 300);

    const clearSelectedItem= () => {
        setItem(null)
        setItemSearchResults([])
    }

    const updateItem = (selected) => {
        const itemToUpdate = {
            itemCode: selected.itemCode,
            subItemCode: selected.subItemCode,
            locationCode: selected.locationCode,
            areaCode: selected.areaCode,
            description: selected.description,
            item: selected.description
        }
        setItem(itemToUpdate);
        props.onChange(itemToUpdate);
    }

    const handleTextChange = async (text) => {
        console.log("Text: ,", text)
        const isValid = validateInput(text);
        if (!isValid) {
            clearSelectedItem();
        }
        setText(text);

        const foundItem = itemInSearchResults(text);
        if (foundItem) {
            console.log("foundItem: ,", foundItem)
            updateItem(foundItem);
        } else if (isValid) {
            clearSelectedItem();
            const result = await fetchItemSearchResults(text.toLowerCase());
            console.log("result2: ,", result)
            if (result.items && result.items.length === 0) {
                setValidationMessage("Not a valid damage item. Please re-enter", false);
                setOptions([]);
            } else {
                console.log("else itemlist: ,");
                setValidationMessage("", true)
                setItemSearchResults(result.items);
                let itemList = [];
                result.items.forEach(item => {itemList.push({id: item.code, label: item.description})});
                console.log("itemList: ,", itemList)
                setOptions(itemList);
            }
        }
    };

    const fetchItemSearchResults = async (text) => {
        setIsLoading(true);
        const result = await itemsSearchAPIDebounced(text);

        if (result.ok) {
            console.log("result0: ,", result)
            setIsLoading(false);
            return result.json();
        } else {
            console.log("result1: ,", result)
            setIsLoading(false);
            console.log("ERR MSG",  "Error retrieving Item list please try again later");
            return {items: []};
        }
    };

    const handleItemChange = (event) => {
        if (event.length) {
            console.log("Event: ,", event)
            const item = itemInSearchResults(event[0].label);
            if (item !== undefined) {
                updateItem(item)
            }
        } else {
            if (event.length === 0) {
                setOptions([]);
            }
            clearSelectedItem();
        }
    };

    const showItemList = () => {
        console.log("showItemList: ,")
        const hasValidItems = text.length > 2 && options.length;
        const itemHasBeenSelected = item !== null;
        return !!hasValidItems && !itemHasBeenSelected;
    };

    const itemInSearchResults = (text) => {
        console.log("itemInSearchResults: ,")
        return itemSearchResults.find(
            (item) => item.description === text
        );
    };

    const validateInput = (text) => {
        console.log("validateInput: ,")
        if (text.length < 3) {
            setValidationMessage("Please enter at least 3 characters in the search", false);
            return false;
        } else {
            setValidationMessage("", true);
            setIsLoading(false);
            return true;
        }
    };

    const setValidationMessage = (message, isValid) => {
        setItemValidationMessage(message);
        setIsItemValid(isValid);
    }


    return (
        <>
            <Form.Group className="flex-grow-1">
                <Input.Label className="font-weight-bold">
                    Item
                </Input.Label>
                <AsyncTypeahead
                    // ref={(ref) => this._typeahead = ref}
                    isLoading={isLoading}
                    emptyLabel={null}
                    name="add-damage-item"
                    id="add-damage-item-list"
                    placeholder="Search All Damage Items"
                    onSearch={handleTextChange}
                    onChange={handleItemChange}
                    options={options}
                    iconRight="prism-icon-search icon"
                    autoComplete="off"
                    useCache={false}
                    open={showItemList()}
                    defaultOpen={false}
                    style={{border: '1px solid #babcbe'}}
                />
            </Form.Group>
            {!isItemValid &&
            <Row className="col-12" style={{marginTop: "-16px"}}>
                <div
                    className="invalid-feedback"
                    id="item-search-validation-message"
                    style={{display: "block"}}>
                        <span>
                          <i className="icon prism-icon-notification-circle text-danger"/>{" "}
                            {itemValidationMessage}
                        </span>
                </div>
            </Row>
            }
        </>
    )

}

export default ItemDropdown;
