import Col from "@prism/col";
import DataPair from "../../../../common/DataPair";
import Row from "@prism/row";
import RadioButton from "@prism/radiobutton";
import React from "react";

const RadioQuestion = (props) => {
    let labelText = props.question.question.slice(0, props.question.question.indexOf('*') === -1 ? props.question.question.length : props.question.question.indexOf('*'));
    let requiredFlag = props.required ? '*' : null

    return (
        <Col className="my-2" id={props.question.id}>
            <DataPair label={<div>{labelText}{requiredFlag && <span className="text-danger">{requiredFlag}</span>}</div>} id={props.question.question}>
                <Row>
                    {props.question.answers.map(answer => {
                        console.log("RADIO ANSWER", props.question.answer, answer)
                        let displayAnswer = '';
                        if(answer === 'true') {
                            displayAnswer = 'Yes';
                        } else if(answer === 'false') {
                            displayAnswer = 'No';
                        } else {
                            displayAnswer = answer;
                        }
                        return (
                            <Col className="flex-grow-0" key={answer}>
                                <RadioButton label={displayAnswer}
                                             name={props.question.id}
                                             value={answer}
                                             onChange={props.onChange}
                                             key={answer}
                                             checked={props.question.answer === answer}
                                />
                            </Col>
                        )
                    })}
                </Row>
                {props.question.invalid && <span className="text-danger font-size-sm"><i className="icon prism-icon-notification-circle text-danger" /> Required</span>}
            </DataPair>
        </Col>
    )
}

export default RadioQuestion;
