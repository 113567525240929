import React from 'react';
import {UnifiedPanel, UnifiedPanelHead} from "./UnifiedPanel";
import Question from "./Question";
import Button from '@prism/button';
import Col from '@prism/col';
import Message from '@prism/message';
import Row from '@prism/row';
import DamageCalculatorTable from "./DamageCalculatorTable";
import Api from "../../../Api";
import damageCalculatorLib from "./damageCalculatorLib";
import CostEffectiveModal from "./CostEffectiveModal";

class DamageCalculator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            damages: [],
            type: '',
            questions: [],
            length: 0,
            width: 0,
            dents: '',
            size: '',
            isValid: true,
            lastCalculationCallFailed: false,
            isRepairCostEffective: true,
            formComplete: false,
            newActionCode: '',
            isRentalReturnType: false
        }
    }

    componentWillMount() {
        this.setState({
            type: this.props.type,
            questions: this.props.questions,
            isRentalReturnType: this.props.type.toLowerCase().startsWith('fordguide') || this.props.type.toLowerCase().startsWith('toyotaguide')
        })
    };

    componentDidUpdate = (prevProps, prevState) => {
        console.log("DamageCalculator componentDidUpdate", prevState.formComplete, this.state.formComplete);
        if (prevState.formComplete === false && this.state.formComplete === true) {
            this.setState(() => {
                console.log("DamageCalculator componentDidUpdate resetting form and marking complete");
                let newState = damageCalculatorLib.resetFormQuestions(this.props.questions);
                newState.formComplete = false;
                return newState;
            })
            this.scrollToBottom();
        }
    };

    handleResetButton = () => {
        let newState = damageCalculatorLib.resetFormQuestions(this.props.questions);
        this.setState(newState, () => {
            console.log('form reset:', newState)
        });
    };

    updateAnswer = (id, answer) => {
        let {newState} = damageCalculatorLib.setAnswer(this.state, id, answer);
        this.setState(newState, this.verifyRequiredQuestions);
    };

    verifyRequiredQuestions = () => {
        let {newState} = damageCalculatorLib.markQuestionsOptional(this.state.questions);
        this.setState(newState, () => {
            console.log("optional questions marked")
        });
    };

    calculateDamageFromCalculateRequest = (data) => {
        return data && data.length && {
            id: this.state.damages.length + 1,
            hours: data[0].totalEstimatedHours,
            cost: data[0].totalEstimatedRepairCost,
            length: this.state.length,
            width: this.state.width,
            dents: this.state.dents,
            size: this.state.size
        };
    };

    deleteDamage = (event) => {
        let damages = this.state.damages.filter((damage) => damage.id !== event.currentTarget.value);
        this.setState({damages: damages})
    };
    fetchDamageEstimatesAndSubmitAnswers = async () => {
        await this.fetchDamageEstimates();
        if (!this.state.isValid) {
            return;
        }
        this.props.updateTurnInCalculatorFlag();
        this.props.updateAction({
            target: {
                value: this.state.newActionCode,
                options: [{value: this.state.newActionCode, text: this.state.newActionDescription}],
                selectedIndex: 0
            }
        });
        if (this.state.newChargeable) {
            this.props.updateChargable({
                target: {
                    value: this.state.newChargeable,
                    options: [{value: this.state.newChargeable}],
                    selectedIndex: 0
                }
            });
        }
    }

    isFordType = () => {
        return this.props.type.toLowerCase().startsWith('fordguide');
    }

    isToyotaType = () => {
        return this.props.type.toLowerCase().startsWith('toyotaguide');
    }

    fetchDamageEstimates = async () => {

        let setStateAndPromise = (toSetFunc) => {
            return new Promise((a, r) => {
                this.setState(toSetFunc, () => {
                    a()
                });
            });
        };

        await setStateAndPromise(() => {
            return {isRepairCostEffective: true}
        })

        await setStateAndPromise((currentState) => {
            let validationState = damageCalculatorLib.validateForm(currentState.questions);
            let measurements = damageCalculatorLib.getAllMeasurements(currentState.questions, currentState.type);
            return {
                width: measurements.width,
                length: measurements.length,
                size: measurements.size,
                dents: measurements.dents,
                isValid: validationState.isValid,
                questions: validationState.questions
            };
        });

        if (!this.state.isValid) {
            return;
        }

        let body;
        if (this.isFordType()) {
            body = JSON.stringify([{
                estimatorType: this.state.type,
                itemDescription: this.props.damage.item,
                itemCode: this.props.damage.itemCode,
                subItemCode: this.props.damage.subItemCode,
                damageDescription: this.props.damage.damage,
                damageCode: this.props.damage.damageCode,
                actionCode: null,
                manufacturerCode: this.props.manufactureCode,
                directTurnIn: this.props.directReturnCode,
                severityCode: this.props.damage.severityCode,
                repairDetails: damageCalculatorLib.getRepairDetails(this.state.questions, this.state.type)
            }]);
        } else if (this.isToyotaType()) {
            body = JSON.stringify([{
                estimatorType: this.state.type,
                itemDescription: this.props.damage.item,
                itemCode: this.props.damage.itemCode,
                subItemCode: this.props.damage.subItemCode,
                damageDescription: this.props.damage.damage,
                damageCode: this.props.damage.damageCode,
                actionCode: null,
                manufacturerCode: this.props.manufactureCode,
                categoryCode: this.props.categoryCode,
                severityCode: this.props.damage.severityCode,
                repairDetails: damageCalculatorLib.getRepairDetails(this.state.questions, this.state.type)
            }]);
        } else {
            body = JSON.stringify([{
                estimatorType: this.state.type,
                itemDescription: this.props.damage.item,
                itemCode: this.props.damage.itemCode,
                subItemCode: this.props.damage.subItemCode,
                damageDescription: this.props.damage.damage,
                damageCode: this.props.damage.damageCode,
                action: this.props.damage.action,
                actionCode: this.props.damage.actionCode,
                repairDetails: damageCalculatorLib.getRepairDetails(this.state.questions, this.state.type)
            }]);
        }

        let newState = {
            lastCalculationCallFailed: false,
            showModal: false,
            formComplete: true,
            newActionCode: '',
            newActionDescription: ''
        };
        let response;
        try {
            console.log("calling calculateRepairHours", this.props.manufactureCode, this.props.directReturnCode, this.props.damage.severityCode);
            response = await Api.calculateRepairHours({body});
            console.log("calculateRepairHours response", response);
            newState.isRepairCostEffective = damageCalculatorLib.isRepairCostEffective(response);
            newState.formComplete = newState.isRepairCostEffective;
            if (this.state.isRentalReturnType) {
                newState.newActionCode = response[0].changedAction
                newState.newActionDescription = response[0].actionDescription
                newState.newChargeable = response[0].chargeable
            }

        } catch (err) {
            newState.lastCalculationCallFailed = true;
            newState.formComplete = false;
            console.log("error response from request", err);
        }

        await setStateAndPromise((currentState) => {
            let newDamage = this.calculateDamageFromCalculateRequest(response);

            if (newDamage && newState.isRepairCostEffective) {
                newState.damages = currentState.damages.concat([newDamage]);
                newState.aluminum = damageCalculatorLib.determineIfAluminumFromCalculateRequest(response);
            }

            console.log("post repair hours newState:", newState);
            return newState;
        });
    };

    handleModalClose = (replace) => {
        if (replace) {
            this.props.updateAction({
                target: {
                    value: 'RP',
                    options: [{value: 'RP', text: 'Replace'}],
                    selectedIndex: 0
                }
            })
            this.props.hideDamageCalculator();
            this.setState({formComplete: true});
        } else {
            this.setState({formComplete: false});
        }
    };

    handleApplyButtonClick = () => {
        let isRepairCostEffective = damageCalculatorLib.totalDamageCost(this.state.damages) < 100000;
        this.setState(() => ({
            isRepairCostEffective
        }), () => {
            if (!this.state.isRepairCostEffective) {
                return;
            }
            let repairLaborHours = damageCalculatorLib.totalDamageHours(this.state.damages);
            let repairLaborCost = damageCalculatorLib.totalDamageCost(this.state.damages);

            this.props.updatePricingForDamage({
                repairLaborHours,
                repairLaborCost,
                aluminum: this.state.aluminum
            });

        });
    };

    scrollToBottom = () => {
        this.calculatorEnd && this.calculatorEnd.scrollIntoView({behavior: "smooth"});
    }

    render() {
        let margin = this.state.lastCalculationCallFailed || !this.state.isValid ? '90px' : '50px'

        return (
            <UnifiedPanel className="bg-white" id={this.props.id}>
                <UnifiedPanelHead className="bg-ash calculator-header">
                    <Row>
                        <Col className="prism-h1 text-left mb-0">
                            <i id="repair-summary-panel-reset-icon" onClick={this.handleResetButton}
                               className="icon prism-icon-export"/>
                        </Col>
                        <Col className="prism-h1 text-right mb-0 pt-2 pr-4">
                            <i id="repair-summary-panel-close-icon" onClick={this.props.hideDamageCalculator}
                               className="icon prism-icon-cross"/>
                        </Col>
                    </Row>
                </UnifiedPanelHead>
                <Row className='bg-white calculator-message'><Col className="pl-0 pr-0">
                    <Message id="damage-calculator-error-message"
                             className='m-2'
                             isOpen={this.state.lastCalculationCallFailed}
                             color='danger'>Calculation failed</Message>
                    <Message id="damage-calculator-error-message"
                             className='m-2'
                             isOpen={!this.state.isValid}
                             color='danger'>Please answer required questions</Message>
                </Col></Row>
                <form style={{marginTop: margin}}>
                    <Row>
                        <Col className="h3 mb-2">
                            {this.state.isRentalReturnType ? 'Turn-in' : this.state.type} {this.state.isRentalReturnType ? 'Calculator' : 'Damages'}
                        </Col>
                    </Row>
                    <Row>
                        {this.state.questions.map(question => {
                            return (
                                <React.Fragment key={question.id}>
                                    <Question question={question}
                                              updateAnswer={this.updateAnswer}/>
                                    {question.nestedQuestions && question.nestedQuestions.map(nestedQuestion => {
                                        if (question.nestedMandatoryIfAnswerIs && question.nestedMandatoryIfAnswerIs.includes(question.answer)) {
                                            nestedQuestion.mandatory = 'Y'
                                        } else if (question.nestedMandatoryIfAnswerIs && !question.nestedMandatoryIfAnswerIs.includes(question.answer)) {
                                            nestedQuestion.mandatory = 'N'
                                            return null;
                                        }
                                        return (

                                            <Question question={nestedQuestion} key={nestedQuestion.id}
                                                      updateAnswer={this.updateAnswer}/>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </Row>
                    {!this.state.isRentalReturnType &&
                        <Button outline type="submit" className="btn-full-width sticky-bottom button-white"
                                onClick={this.fetchDamageEstimates}>Calculate & Add Damage</Button>
                    }
                    {this.state.isRentalReturnType &&

                        <Row className="mb-4 flex-row-reverse">
                            <Col><Button color="primary" className="btn-full-width"
                                         onClick={this.fetchDamageEstimatesAndSubmitAnswers}>DONE</Button></Col>
                            <Col><Button color="secondary" className="btn-full-width"
                                         onClick={this.props.hideDamageCalculator}>Cancel</Button></Col>
                        </Row>
                    }
                </form>

                {!this.state.isRentalReturnType && this.state.damages.length > 0 &&
                    <>
                        <Row>
                            <Col>
                                <Row className="my-3 p-0">
                                    <Col className="px-0">
                                        <Row><Col><span className="h3"
                                                        style={{fontSize: '1.8rem'}}>Results</span></Col></Row>
                                        <Row><Col><span
                                            className="font-weight-light">{this.state.type} Damages</span></Col></Row>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <DamageCalculatorTable type={this.state.type}
                                                               damages={this.state.damages}
                                                               deleteDamage={this.deleteDamage}/>
                                    </Col>
                                </Row>
                                <Row className="mb-3"><Col><Button className="btn-full-width"
                                                                   onClick={this.handleApplyButtonClick}
                                                                   color="primary">Apply</Button></Col></Row>
                                <Row className="mb-3"><Col><Button outline className="btn-full-width"
                                                                   onClick={this.props.hideDamageCalculator}>Cancel</Button></Col></Row>
                            </Col>
                        </Row>
                        <Row>
                            <div ref={(el) => {
                                this.calculatorEnd = el;
                            }} style={{width: '0'}}></div>
                        </Row>
                    </>
                }
                <CostEffectiveModal isRepairCostEffective={this.state.isRepairCostEffective}
                                    formComplete={this.state.formComplete}
                                    handleModalClose={this.handleModalClose}
                />
            </UnifiedPanel>
        )
    }
}

export default DamageCalculator;
