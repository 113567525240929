import React, {useState, useEffect, useRef} from 'react';
import Api from '../../../Api';
import Dropdown from "../../../common/Dropdown";
import isEqual from "lodash.isequal";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import {PLEASE_SELECT} from "../../../../utils/constants";

function ActionDropdown(props) {

    const [allOptions, setAllOptions] = useState([]);
    const [defaultActionCode, setDefaultActionCode] = useState('');
    const prevProps = useRef({});

    useEffect(() => {

        function isMissingInputs(props) {
            if (!props) {
                return true
            }
            return!props.item.itemCode || !props.item.subItemCode || !props.damageCode || !props.severityCode;
        }

        function findDefaultOption(response) {
            return response.items.find(i => i.action.hasOwnProperty('default'));
        }

        async function getActions(signal, props) {
            return Api.getActions({
                ...props.item,
                damageCode: props.damageCode,
                severityCode: props.severityCode,
                groupCode: props.groupCode,
                category: props.categoryCode,
                auctionCode: props.auctionCode,
                signal: signal,
                listOptions: true
            });
        }

        function setDefaultOption(response) {
            const defaultOption = findDefaultOption(response);

            if (defaultOption) {
                setDefaultActionCode(defaultOption.action.code);
                if (!props.value) {
                    fireOnChange(defaultOption);
                }
            } else {
                if (!props.value) {
                    console.log("ART API responded with no default action: ", response);
                    props.clearAndValidatePricing();
                }
            }
        }

        function fireOnChange(actionItem) {
            props.onChange({
                target: {
                    value: actionItem.action.code,
                    options: [{text: actionItem.action.description}],
                    selectedIndex: 0
                }
            })
        }

        if (!isEqual(prevProps.current, props)) {

            if (isMissingInputs(props)) {
                setAllOptions([]);
                prevProps.current = props;
            } else if (prevProps.current.severityCode !== props.severityCode) {
                const abortController = new AbortController();
                const signal = abortController.signal;


                getActions(signal, props).then((response) => {
                    if (!response.options) {
                        setAllOptions([]);
                        props.setStatusMessage("There was a problem fetching Action list");
                        console.log("ART API response for action: ", response);
                        props.clearAndValidatePricing();
                        return;
                    }

                    if (!response.options.find(item => item.code === props.value)) {
                        props.value && response.options.push({code: props.value, description: props.description})
                    }

                    setAllOptions(response.options);
                    setDefaultOption(response);

                });


                prevProps.current = props;
            }
        }

    }, [props]);

    return (
        <Dropdown disabled={(props.value === '' && allOptions.length === 0) || props.disabled} onChange={props.onChange}
                  value={props.value || defaultActionCode}
                  id='action-dropdown-comp'>
            {allOptions.length === 0 && props.value && <option value={props.value}>{props.description}</option>}
            {!props.value && <option value=''>{PLEASE_SELECT}</option>}
            {allOptions.length && allOptions.map(option => {
                return (<option key={option.code}
                                value={option.code}>{option.description}</option>)
            })}
        </Dropdown>
    )

}

export default ActionDropdown;
